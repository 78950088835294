import { ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';

import HowToHypersayMenu from '../../components/howToHypersayMenu';
import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import { withPrefix } from 'gatsby';
import * as styles from './howtohypersay.module.scss';

const Business = () => (
  <LayoutBlog>
    <SEO
      title="How to Hypersay - Use cases - Business"
      description="Hypersay helps your business deliver engaging and exciting presentations. Townhall meeting? Done!"
      keywords={[
        'live presentation',
        'interactive presentations',
        'business',
        'corporate',
        'training',
        'townhall meetings',
        'powerpoint',
        'google slides',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <T tagName="h1" id="Hypersay for Business" />
      <T
        tagName="p"
        id="Our 180K users enjoy Hypersay for very different reasons, based on their professions. Below you can find several use cases."
      />

      <HowToHypersayMenu active="business" />
      <div className={styles.item}>
        <div className={styles.image}>
          <img src={withPrefix('/images/use-cases/tree.png')} alt="a tree" />
        </div>
        <div className={styles.text}>
          <T tagName="h2" id="Hug a tree" />
          <T
            tagName="p"
            id="Powerpoint and business cards remain the lifeblood of corporate communication. One always follows the other.  Even with today’s full adoption of digital technology the business world still largely depends on cutting down trees to make a sale."
          />
          <T
            tagName="p"
            id="Hypersay, an eco-friendly alternative to a tree, converts sales presentations into interactive dialogues with prospective clients. By digitally sharing the session code to a sales presentation, the client and salesperson have an equal voice in understanding the client needs and the product offering. The digital exchange of information permits API’s to be built to any CRM. Business cards can be complemented by (or, gasp, even replaced with) a digital handshake."
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>
          <T tagName="h2" id="Monthly Town Hall, again?" />
          <T
            tagName="p"
            id="Repetition breeds boredom and monthly town hall updates can be a source of commercial waste. Yet information, communication and strategic focus are the basic elements to commercial success."
          />
          <T
            tagName="p"
            id="Hypersay takes the foundations of business communication and injects the necessary fuel to propel them into the digital world. Our functionality allows both local and remote based employees to feel the heat of a dialogue inside the conference room. Hypersay can turn a monthly update into an interactive dialogue that raises the awareness of business achievements and failures for all to ponder, until the next monthly town hall meeting."
          />
        </div>
        <div className={styles.image}>
          <img
            src={withPrefix('/images/use-cases/townhall.png')}
            alt="townhall meeting"
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.image}>
          <img src={withPrefix('/images/use-cases/egg.png')} alt="an egg" />
        </div>

        <div className={styles.text}>
          <T tagName="h2" id="Break the egg and make an omelette" />
          <T
            tagName="p"
            id="Everyone was once a student. Then they (hopefully) become an employee. But when you teach an employee it is called ‘corporate training’ and the specific goals and objectives are different from a student."
          />
          <T
            tagName="p"
            id="Hypersay permits you to break the boredom of a standard slide presentation and deliver engaging and interactive corporate training. The functionality within Hypersay will add spice to your corporate training content and challenge, stimulate and motivate your employees to learn."
          />
          <T
            tagName="p"
            id="If your corporate training is a slide presentation, is it yesterday’s breakfast?"
          />
        </div>
      </div>
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Business;
